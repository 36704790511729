import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Footer'; // Import the Footer component
import Confetti from 'react-confetti'; // Import the Confetti component
import rightarrow from '../../images/right-arrow.png';
import './SuccessPage.css';

const SuccessPage = () => {
  const location = useLocation();
  const { title, budget } = location.state || {};
  const navigate = useNavigate();

  // State to control confetti display
  const [confettiActive, setConfettiActive] = useState(true);

  useEffect(() => {
    // Stop the confetti after 5 seconds
    const timer = setTimeout(() => {
      setConfettiActive(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="success-page-container">
      {confettiActive && <Confetti />}
      <div className="success-page-content">
        <h1>Congratulations!</h1>
        <p className="confirmation-text">
          Your project has been uploaded.
        </p>

        <div className="project-details">
          <div className="project-title-budget">
            <h2>{title}</h2>
            <p>${budget}</p>
          </div>
        </div>

        <p className="my-projects-button" onClick={() => navigate('/business-projects')}>
          my projects{' '}
        </p>
      </div>

      <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default SuccessPage;
