// src/components/BusinessProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

function BusinessProtectedRoute({ children }) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Fetch user role from Firestore
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (userData.role === 'business') {
              setIsAuthorized(true);
            } else {
              navigate('/signin');
            }
          } else {
            // User document doesn't exist
            navigate('/signin');
          }
        } catch (error) {
          console.error('Error checking user role:', error);
          navigate('/signin');
        }
      } else {
        navigate('/signin');
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  if (loading) return <p>Loading...</p>;

  return isAuthorized ? children : null;
}

export default BusinessProtectedRoute;
