import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { sendPasswordResetEmail } from 'firebase/auth';
import './StudentProfile.css';
import Footer from '../components/Footer';

const StudentProfile = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pastProjects, setPastProjects] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserDetails(data);
            setFirstName(data.firstName || '');
            setLastName(data.lastName || '');
            setPastProjects(data.pastProjects || '');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          firstName,
          lastName,
          pastProjects,
        });
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          firstName,
          lastName,
          pastProjects,
        }));
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleChangePassword = () => {
    const user = auth.currentUser;
    if (user) {
      sendPasswordResetEmail(auth, user.email)
        .then(() => {
          alert('Password reset email sent!');
        })
        .catch((error) => {
          console.error('Error sending password reset email:', error);
        });
    }
  };

  return (
    <div className="profile-container">
      <div className="content">
        <h1 className="name">
          {userDetails.firstName} {userDetails.lastName}
        </h1>
        <h2 className="account-title">Account Details</h2>

        <div className="account-details">
          {/* First Name */}
          <div className="detail-row">
            <p className="detail-label">First Name</p>
            {isEditing ? (
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="detail-input"
              />
            ) : (
              <p className="detail-value">{userDetails.firstName || 'First Name'}</p>
            )}
          </div>

          {/* Last Name */}
          <div className="detail-row">
            <p className="detail-label">Last Name</p>
            {isEditing ? (
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="detail-input"
              />
            ) : (
              <p className="detail-value">{userDetails.lastName || 'Last Name'}</p>
            )}
          </div>

          {/* Email Address */}
          <div className="detail-row">
            <p className="detail-label">Email Address</p>
            <p className="detail-value">{auth.currentUser?.email || 'Email Address'}</p>
          </div>

          {/* Password */}
          <div className="detail-row">
            <p className="detail-label">Password</p>
            <p className="change-password" onClick={handleChangePassword}>
              Change Password
            </p>
          </div>

          {/* Past Projects */}
          <div className="detail-row">
            <p className="detail-label">Past Projects</p>
            {isEditing ? (
              <input
                type="text"
                value={pastProjects}
                onChange={(e) => setPastProjects(e.target.value)}
                className="detail-input"
              />
            ) : (
              <p className="detail-value">{userDetails.pastProjects || 'N/A'}</p>
            )}
          </div>

          {/* Edit and Save Buttons */}
          <p className="edit-details" onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel' : 'Edit Details'}
          </p>
          {isEditing && (
            <p className="save" onClick={handleSave}>
              Save Changes
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentProfile;
