import React from 'react';
import './AIPrecision.css'; // Import the CSS styling

function Blog3BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Live Collaboration</h2>
        <p className="ai-precision-text">
        Moccet's live collaboration tools make staying in tune with your 
        project's progress effortless. 
        Our platform offers a <span className="highlight">seamless, 
        real-time environment </span>where you can exchange feedback, 
        share updates, and track milestones—all in one place. 
        This feature is particularly valuable for companies seeking continuous engagement, 
        allowing you to <span className="highlight">refine outcomes in real time</span> and ensure the final 
        result aligns precisely with your expectations.
        </p>
        <p className="ai-precision-text">
        This level of interaction not only streamlines the collaborative process 
        but keeps both companies and talent aligned toward shared goals. 
        By embedding real-time interaction within the project journey, 
        Moccet ensures your project remains <span className="highlight">dynamic and adaptable</span>, 
        regardless of its complexity or nuanced demands.
        </p>
      </div>
    </div>
  );
}

export default Blog3BS;
