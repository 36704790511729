// src/pages/PaymentPage.js

import React, { useState, useEffect } from 'react';
import {
  useStripe,
  useElements,
  CardElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useNavigate, useLocation } from 'react-router-dom';
import './PaymentPage.css';
import stripeLogo from '../../images/stripe.png';

const PaymentPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    budget = 0,
    title,
    projectObjective,
    projectSummary,
    deliverables,
    skills,
    dataInsights,
    assistantResponse,
    description,
    ownerId,
    isCollaborating,
    collaboratingBusinessId,
    studentId,
    createdAt,
  } = location.state || {};

  const [clientSecret, setClientSecret] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [country, setCountry] = useState('US');
  const [address, setAddress] = useState('');
  const [fullName, setFullName] = useState('');

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestAvailable, setPaymentRequestAvailable] = useState(false);

  console.log('State received in Stripe page:', location.state);

  useEffect(() => {
    if (!budget) {
      setError('Budget is not provided. Please set your budget first.');
      navigate('/set-budget'); // Redirect if budget is not provided
    }
  }, [budget, navigate]);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await fetch('/api/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ amount: budget }), // Amount in dollars
        });

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (err) {
        console.error('Error fetching payment intent:', err);
        setError('Failed to initiate payment.');
      }
    };

    createPaymentIntent();
  }, [budget]);

  useEffect(() => {
    if (stripe && clientSecret) {
      const pr = stripe.paymentRequest({
        country: country,
        currency: 'usd',
        total: {
          label: 'Total',
          amount: budget * 100, // Convert dollars to cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          setPaymentRequestAvailable(true);
        }
      });

      pr.on('paymentmethod', async (event) => {
        // Handle payment
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          { handleActions: false }
        );

        if (confirmError) {
          event.complete('fail');
          setError(`Payment failed: ${confirmError.message}`);
        } else {
          event.complete('success');
          if (paymentIntent.status === 'requires_action') {
            const { error: actionError, paymentIntent: newPaymentIntent } = await stripe.confirmCardPayment(clientSecret);
            if (actionError) {
              setError(`Payment failed: ${actionError.message}`);
            } else {
              handlePaymentSuccess(newPaymentIntent);
            }
          } else {
            handlePaymentSuccess(paymentIntent);
          }
        }
      });
    }
  }, [stripe, budget, clientSecret, country]);

  const handlePaymentSuccess = async (paymentIntent) => {
    setSuccess(true);
    setError(null);
    setProcessing(false);

    // Add project to Firestore via backend
    try {
      const response = await fetch('/api/upload-project', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title,
          description,
          projectObjective,
          projectSummary,
          deliverables,
          skills,
          dataInsights,
          assistantResponse,
          budget,
          ownerId,
          isCollaborating: isCollaborating || false,
          collaboratingBusinessId: collaboratingBusinessId || null,
          studentId: studentId || ownerId,
          createdAt: createdAt || new Date(),
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to upload project.');
      }

      navigate('/success', { state: { title, budget, projectSummary } });
    } catch (uploadError) {
      console.error('Error uploading project:', uploadError);
      alert('Failed to upload project after payment.');
    }
  };

  const handlePayPalSuccess = async (details, data) => {
    setSuccess(true);
    setError(null);
    setProcessing(false);

    // Send details to your server for verification and further processing
    try {
      const response = await fetch('/api/paypal-payment-success', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderID: data.orderID, details }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Failed to process PayPal payment.');
      }

      // Proceed with your post-payment logic
      navigate('/success', { state: { title, budget, projectSummary } });
    } catch (error) {
      console.error('Error processing PayPal payment:', error);
      setError('Payment processing error.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!clientSecret) {
      setError('Payment processing error. Missing client secret.');
      setProcessing(false);
      return;
    }

    try {
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: fullName,
              address: {
                country: country,
                line1: address,
              },
            },
          },
        }
      );

      if (stripeError) {
        setError(`Payment failed: ${stripeError.message}`);
        setProcessing(false);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        handlePaymentSuccess(paymentIntent);
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Payment processing error.');
      setProcessing(false);
    }
  };

  // Stripe Element styles
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '16px',
        color: '#32325d',
        '::placeholder': {
          color: '#a0aec0',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
    hidePostalCode: true,
  };

  return (
    <div className="payment-container">
      <form onSubmit={handleSubmit}>
        <h3>Checkout</h3>
        <h3>Total: ${Number(budget).toFixed(2)}</h3>

        {/* PayPal Button
        <PayPalScriptProvider options={{ "client-id": "AXGDBnbDn5-lb19NbHwTceiP_nRTjOnL4juYfAOsq6oyFPBE3u4SLMUYuLgWunbUZMUfQs2k_J_FXzEE" }}>
          <PayPalButtons
    style={{ layout: 'vertical' }}
    createOrder={(data, actions) => {
      return actions.order.create({
        purchase_units: [{
          amount: {
            value: budget.toString(),
          },
        }],
      });
    }}
    onApprove={(data, actions) => {
      return actions.order.capture().then((details) => {
        handlePayPalSuccess(details, data);
      });
    }}
    onError={(err) => {
      console.error('PayPal Checkout onError', err);
      setError('PayPal payment failed.');
    }}
  />
</PayPalScriptProvider>

        {/* Payment Request Button (Apple Pay and others) */}
        {/* {paymentRequestAvailable && (
          <>
            <h4>Or pay with Apple Pay / Google Pay:</h4>
            <div className="form-row">
              <PaymentRequestButtonElement
                options={{ paymentRequest }}
                className="payment-request-button"
              />
            </div>
          </>
        )}

        {/* Or pay with card */}
        {/* <h4>Or pay with card:</h4>   */}

        <div className="form-row">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full name"
            className="payment-input"
            required
          />
        </div>

        <div className="form-row">
          <label htmlFor="country">Country</label>
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="payment-input"
            required
          >
            <option value="">Select Country</option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
            <option value="GB">United Kingdom</option>
            <option value="AU">Australia</option>
            {/* Add more countries as needed */}
          </select>
        </div>

        <div className="form-row">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Street address"
            className="payment-input"
            required
          />
        </div>

        <div className="form-row">
          <label htmlFor="cardElement">Card Details</label>
          <div className="card-element-wrapper">
            <CardElement
              id="cardElement"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <button
          className="pay-button"
          type="submit"
          disabled={processing || success}
        >
          {processing ? 'Processing...' : `Pay $${budget.toFixed(2)}`}
        </button>

        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}

        <p className="secure-payment">
          🔒 All transactions are secure and encrypted.
        </p>

        <div className="powered-by-stripe">
          <p>Payments powered by</p>
          <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
            <img
              src={stripeLogo}
              alt="Stripe"
            />
          </a>
        </div>
      </form>
    </div>
  );
};

export default PaymentPage;
