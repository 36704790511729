// HowItWorks.js

import React, { useEffect, useRef } from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  const containerRef = useRef(null);

  const features = [
    {
      title: 'Business Strategy',
      description:
        'Get innovative, data-backed insights to shape your next big move with strategy support.',
    },
    {
      title: 'Marketing Plan',
      description:
        'Discover creative marketing solutions tailored to your audience by some of the brightest minds in the field.',
    },
    {
      title: 'Website/ UI Design',
      description:
        'Elevate your digital presence with innovative, user-friendly designs crafted by skilled designers',
    },
    {
      title: 'Public Health Research',
      description:
        'Tackle complex health challenges with rigorous, research-driven insights from public health experts',
    },
    {
      title: 'Educational Content',
      description:
        'Engage and educate your audience with expertly developed content.',
    },
    {
      title: 'Coding',
      description:
        'Bring your ideas to life with cutting-edge code and development skills from  tech leaders.',
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          entries[0].target.classList.add('in-view');
          observer.unobserve(entries[0].target);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div className="how-it-works-container" ref={containerRef}>
      <div className="how-it-works-container1">
        <h1>Project Suggestions</h1>
      </div>

      <div className="features-grid">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
