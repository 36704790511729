import React from 'react';
import './Create.css';
import WorkImage from '../../images/work.png';
import { useNavigate } from 'react-router-dom';

const Work = () => {
    const navigate = useNavigate(); // useNavigate hook

      // Function to handle navigation on link clicks
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="create-container1">
        <div className="work-section">
        <img src={WorkImage} alt="Create" className="work-image" />
      </div>
      <div className="text-section">
        <h1>
        Connecting you to world-class opportunities
        </h1>
        <p>
        At Moccet, we believe that bringing brilliant minds together drives incredible results. Our platform is a celebration of innovation, collaboration, and the power of connecting talent with transformative projects.
        </p>
        <div className="container2">
        <button className="get-started-btn" onClick={() => handleClick('/signup')}>Sign up</button>
        </div>
      </div>
    </div>
  );
};

export default Work;