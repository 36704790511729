// src/components/BusinessProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { getIdTokenResult, onAuthStateChanged } from 'firebase/auth';

function StudentProtectedRoute({ children }) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idTokenResult = await getIdTokenResult(user);
          if (idTokenResult.claims.role === 'student') {
            setIsAuthorized(true);
          } else {
            navigate('/signin');
          }
        } catch (error) {
          console.error('Error checking user role:', error);
          navigate('/signin');
        }
      } else {
        navigate('/signin');
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  if (loading) return <p>Loading...</p>;

  return isAuthorized ? children : null;
}

export default StudentProtectedRoute;
