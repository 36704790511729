import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Changed BrowserRouter to HashRouter
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import SignIn from './components/auth/SignIn';
import PaymentPage from './components/stripe/PaymentPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import StudentProtectedRoute from './components/auth/StudentProtectedRoute';
import BusinessProtectedRoute from './components/auth/BusinessProtectedRoute';
import ProjectBoard from './components/projects/ProjectBoard';
import BusinessDash from './pages/BusinessDash';
import ApplicationsList from './components/projects/ApplicationsList';
import BusinessChatPage from './components/chat/BusinessChatPage';
import StudentChatPage from './components/chat/StudentChatPage';
import StudentChats from './components/chat/StudentChats';
import StudentDash from './pages/StudentDash';
import BusinessChats from './components/chat/BusinessChats';
import MergedSignUp from './components/auth/MergedSignUp';
import StudentProfile from './pages/StudentProfile';
import BusinessProfile from './pages/BusinessProfile';
import MyStudentProjects from './pages/MyStudentProjects';
import ConfirmOptimization from './components/projects/ConfirmOptimization';
import SetBudget from './components/projects/SetBudget';
import SuccessPage from './components/projects/SuccessPage';
import MyBusinessProjects from './components/projects/MyBusinessProjects';
import ProjectOptimization from './components/projects/ProjectOptimization';
import AIPrecision from './blogs/business/AIPrecision';
import Blog2BS from './blogs/business/Blog2BS';
import Blog3BS from './blogs/business/Blog3BS';
import Blog4BS from './blogs/business/Blog4BS';
import Blog5BS from './blogs/business/Blog5BS';
import Blog6BS from './blogs/business/Blog6BS';
import Blog1T from './blogs/talent/Blog1T';
import Blog2T from './blogs/talent/Blog2T';
import Blog3T from './blogs/talent/Blog3T';
import Blog4T from './blogs/talent/Blog4T';
import Blog5T from './blogs/talent/Blog5T';
import Blog6T from './blogs/talent/Blog6T';
import BusinessSignUp from './components/auth/BusinessSignUp';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    
    <Router>
      <NavBar />
        <Routes>
          <Route path="/project-optimization" element={<ProjectOptimization />} />
          <Route path="/confirm-optimization" element={<ConfirmOptimization />} />
          <Route path="/set-budget" element={<SetBudget />} />
          <Route path="/success" element={<BusinessProtectedRoute><SuccessPage /></BusinessProtectedRoute>} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/" element={<HomePage />} />
          <Route path="myprojects" element={<MyStudentProjects/>}/>
          <Route path="/signup" element={<MergedSignUp/>}/>
          <Route path="/business-signup" element={<BusinessSignUp />} />
          <Route path="/AIPrecision" element={<AIPrecision />}/>
          <Route path="/ExclusiveTalent" element={<Blog2BS />}/>
          <Route path="/LiveCollaboration" element={<Blog3BS />}/>
          <Route path="/SetYourValue" element={<Blog4BS />}/>
          <Route path="/RapidResults" element={<Blog5BS />}/>
          <Route path="/Imagine" element={<Blog6BS />}/>
          <Route path="/AdvancedAI" element={<Blog1T />}/>
          <Route path="/YourPassion" element={<Blog2T />}/>
          <Route path="/CollaboratePlus" element={<Blog3T />}/>
          <Route path="/Experience" element={<Blog4T />}/>
          <Route path="/Creativity" element={<Blog5T />}/>
          <Route path="/FastPayments" element={<Blog6T/>}/>
          <Route path="/payment" element={<ProtectedRoute><Elements stripe={stripePromise}><PaymentPage /></Elements></ProtectedRoute>} />
          <Route path="/student-dashboard" element={<StudentProtectedRoute><StudentDash /></StudentProtectedRoute>} />
          <Route path="/student-chats" element={<StudentProtectedRoute><StudentChats /></StudentProtectedRoute>} />
          <Route path="/student-profile" element={<StudentProtectedRoute><StudentProfile /></StudentProtectedRoute>} />
          <Route path="/my-projects" element={<StudentProtectedRoute><MyStudentProjects /></StudentProtectedRoute>} />
          <Route path="/project-board" element={<StudentProtectedRoute><ProjectBoard /></StudentProtectedRoute>} />
          <Route path="/business-profile" element={<BusinessProtectedRoute><BusinessProfile /></BusinessProtectedRoute>} />
          <Route path="/business-dashboard" element={<BusinessProtectedRoute><BusinessDash /></BusinessProtectedRoute>} />
          <Route path="/business-projects" element={<BusinessProtectedRoute><MyBusinessProjects /></BusinessProtectedRoute>} />
          <Route path="/applications" element={<BusinessProtectedRoute><ApplicationsList /></BusinessProtectedRoute>} />
          <Route path="/chat/:chatId" element={<BusinessProtectedRoute><BusinessChatPage /></BusinessProtectedRoute>} />
          <Route path="/business-chats" element={<BusinessProtectedRoute><BusinessChats /></BusinessProtectedRoute>} />
          <Route path="/student-chat/:chatId" element={<StudentProtectedRoute><StudentChatPage /></StudentProtectedRoute>} />
        </Routes>
    </Router>
  );
}

export default App;
