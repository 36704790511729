// src/components/ProjectOptimization.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import './ProjectOptimization.css';
import Footer from '../Footer';

const API_BASE_URL = process.env.NODE_ENV === 'production' 
    ? ''
    : 'http://localhost:5001';


const ProjectOptimization = () => {
  const location = useLocation();
  const {
    businessProblem = '',
    businessField = '',
    description = '',
  } = location.state || {};
  
  // Combine the data into one projectDescription
  const initialProjectDescription = [businessProblem, description]
    .filter((str) => str && str.trim() !== '')
    .join('\n\n');
  
  const [projectDescription, setProjectDescription] = useState(initialProjectDescription);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to auto-resize textareas
  const autoResize = (e) => {
    e.target.style.height = 'auto';
    const newHeight = e.target.scrollHeight;
    const minHeight = 50; // Ensure this matches your CSS min-height
    e.target.style.height = Math.max(newHeight, minHeight) + 'px';
  };

  const handleOptimize = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/upload`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ projectDescription }),
      });
      const data = await response.json();
      const assistantResponse = data.assistantResponse || 'No response received.';

      console.log(assistantResponse);
      
      // Generate the summary based on the assistant's response or other fields
      const summary = assistantResponse.substring(0, 100); // Example logic to create a short summary
      
      const ownerId = auth.currentUser?.uid || '';
      const isCollaborating = false;
      const collaboratingBusinessId = null;
      const studentId = ownerId;
      const createdAt = new Date();

      // Navigate to the confirmation page with all necessary fields
      navigate('/confirm-optimization', {
          state: {
              assistantResponse,
              projectDescription,
              summary, // Include the summary here
              ownerId,
              isCollaborating,
              collaboratingBusinessId,
              studentId,
              createdAt,
          },
      });
    } catch (error) {
      console.error('Error processing optimization:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="project-optimization-container">
      {loading ? (
        <div className="loading-screen">
          <h2 className="loading-title">Please hold while Moccet’s AI gathers insights and refines <span>your </span>project</h2>
          <div className="dots-container">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
            <div className="dot dot5"></div>
          </div>
          <p className="loading-text">
            Did you know, Moccet’s AI leverages hundreds of data points and in-depth market trend analyses to optimize your project brief, delivering unparalleled value for you and maximizing returns.
          </p>
        </div>
      ) : (
        <>
          <h2 className="title">Complete <span>your</span> project with Moccet</h2>
          <div className="main-content">
            <textarea
              className="input-box"
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              onInput={autoResize}
              placeholder="Describe your project here. Write down whatever you can imagine, giving as much or as little information as you’d like. Our AI will study the market based on this and refine your idea for you creating your brief..."
            />
            <p onClick={handleOptimize} className={`submit-button ${loading ? 'loading' : ''}`}>
              Upload
            </p>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default ProjectOptimization;
