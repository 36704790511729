// src/components/ProjectBoard.js

import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { collection, query, getDocs, addDoc, doc, getDoc, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './ProjectBoard.css';
import submitted from '../../images/submitted.png';
import rightarrow from '../../images/right-arrow.png';
import uparrow from '../../images/up-arrow.png';
import downarrow from '../../images/down-arrow.png';
import Footer from '../Footer';

function ProjectBoard() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [submittedProjects, setSubmittedProjects] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchSubmittedProjects = async () => {
      const user = auth.currentUser;
      if (user) {
        const applicationsQuery = query(
          collection(db, 'applications'),
          where('studentId', '==', user.uid)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);
        const submittedProjectIds = applicationsSnapshot.docs.map(doc => doc.data().projectId);
        setSubmittedProjects(submittedProjectIds);
      }
    };

    fetchSubmittedProjects();
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/signin');
      } else {
        fetchProjects();
      }
    };

    const fetchProjects = async () => {
      try {
        const q = query(collection(db, 'projects'));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          console.log('No projects found');
        } else {
          console.log('Projects found:', querySnapshot.docs.length);
        }
        const projectsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProjects(projectsList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    checkUser();
  }, [navigate]);

  const applyForProject = async (project) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        alert('You need to be signed in to apply for projects.');
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const { firstName, lastName, university, degree, year } = userDoc.data();

        await addDoc(collection(db, 'applications'), {
          projectId: project.id,
          projectName: project.title,
          studentId: user.uid,
          studentName: `${firstName} ${lastName}`,
          university: university,
          degree: degree,
          year: year,
          businessId: project.ownerId,
          appliedAt: new Date(),
          isCollaborating: false,
          message: '',
        });

        await addDoc(collection(db, 'notifications'), {
          businessId: project.ownerId,
          studentId: user.uid,
          projectId: project.id,
          message: `A student has applied for your project: ${project.title}`,
          createdAt: new Date(),
          read: false,
        });

        // Update the state to reflect that this project has been applied to
        setSubmittedProjects([...submittedProjects, project.id]);
      }
    } catch (error) {
      console.error('Error applying for the project:', error);
      alert('Failed to apply for the project. Please try again later.');
    }
  };

  const handleNextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const handlePreviousProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  if (loading) return <p>Loading projects...</p>;

  const currentProject = projects[currentProjectIndex];

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = (e) => {
    e.stopPropagation();
    setShowPopup(false);
  };

  return (
    <div className={`dashboard-container3456 ${loaded ? 'loaded' : ''}`}>
      {/* Content Wrapper */}
      <div className="content-wrapper">
        {/* Project Board Wrapper */}
        <div className="project-board-wrapper">
          <div className="navigation-arrow-up" onClick={handlePreviousProject}>
            <img src={uparrow} alt="Up Arrow" className="arrow3145" />
          </div>
          <div className="project-card">
            <div className="project-card-left">
              <div className="project-title-budget">
                <h3>{currentProject.title}</h3>
                {/* Display 75% of the budget */}
                <p className="project-budget">
                  ${((currentProject.budget * 0.8).toFixed(2))}
                </p>
              </div>
            </div>
            <div className="project-card-center">
              <p>{currentProject.projectSummary}</p>
              <p className="open-details" onClick={openPopup}>Open full project details</p>
            </div>
            <div className="project-card-right">
              {submittedProjects.includes(currentProject.id) ? (
                <div className="submitted-status">
                  <img src={submitted} alt="Submitted" />
                </div>
              ) : (
                <p onClick={() => applyForProject(currentProject)}>Collaborate</p>
              )}
            </div>
          </div>
          <div className="navigation-arrow-down" onClick={handleNextProject}>
            <img src={downarrow} alt="Down Arrow" className="arrow3145" />
          </div>
        </div>

        {/* Popup for Full Project Details */}
        {showPopup && (
          <div className="popup-overlay" onClick={closePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <button className="close-button" onClick={closePopup}>X</button>
              <div className="popup-inner-content">
                <h2>{currentProject.title}</h2>
                <p><strong>Budget:</strong> ${((currentProject.budget * 0.8).toFixed(2))}</p>
                <p><strong>Project Objective:</strong> {currentProject.projectObjective}</p>
                <p><strong>Project Summary:</strong> {currentProject.projectSummary}</p>
                <p><strong>Deliverables:</strong> {currentProject.deliverables}</p>
              </div>
            </div>
          </div>
        )}

        {/* Dashboard Links Container */}
        <div className="dashboard-links-container" onClick={() => navigate('/my-projects')}>
          <div className="dashboard-links">
            <span>
              My Projects
              <span className="arrow314">
                <img src={rightarrow} alt="Right Arrow" className="arrow314" />
              </span>
            </span>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default ProjectBoard;
