import React, { useState } from 'react';
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword, getIdTokenResult, sendPasswordResetEmail } from 'firebase/auth'; // Add sendPasswordResetEmail
import { useNavigate } from 'react-router-dom';
import './SignIn.css'; // Import the CSS file

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState(''); // Add state for reset message
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setError(''); // Clear any previous errors

    try {
      await signInWithEmailAndPassword(auth, email, password);

      // Force refresh the token
      await auth.currentUser.getIdToken(true);
      const idTokenResult = await getIdTokenResult(auth.currentUser);

      if (idTokenResult.claims.role === 'student') {
        navigate('/student-dashboard');
      } else if (idTokenResult.claims.role === 'business') {
        navigate('/business-dashboard');
      } else {
        navigate('/');
      }

    } catch (error) {
      setError('Error signing in. Please check your credentials and try again.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignIn();
    }
  };

  const handlePasswordReset = async () => {
    setError(''); // Clear previous errors
    setResetMessage(''); // Clear previous reset messages

    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setError('Error sending password reset email. Please try again.');
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-content">
        <div className="login-left">
          <h2>log in</h2>
        </div>
        <div className="login-right123">
          {error && <p className="error-text">{error}</p>}
          {resetMessage && <p className="reset-message">{resetMessage}</p>} {/* Display reset message */}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
            onKeyDown={handleKeyDown}  // Add keypress handler here
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
            onKeyDown={handleKeyDown}  // Add keypress handler here
          />
        </div>
      </div>
      <div className="signin-button-container">
        <p onClick={handleSignIn}>Sign In</p>
        <p className="forgot-password" onClick={handlePasswordReset}>Forgot my password</p> {/* Moved below Sign In */}
      </div>
    </div>
  );
}

export default SignIn;
