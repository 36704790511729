import React from 'react';
import './blog.css'; // Import the CSS styling

function Blog5BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Unleash Your Creativity</h2>
        <p className="ai-precision-text">
        Moccet is your canvas to bring any idea to life, 
        from pitch decks and brand logos to <span className="highlight">marketing calendars, copywriting, and website optimization. </span>
        Our platform offers a variety of projects across creative and strategic fields, 
        allowing you to <span className="highlight">experiment, innovate</span>, and showcase your unique approach.
        </p>
        <p className="ai-precision-text">
        Whether you're looking to sharpen existing skills or explore new <span className="highlight">creative avenues</span>, 
        Moccet offers the freedom to turn your ideas into <span className="highlight">impactful solutions</span>, all while collaborating with top-tier peers.
        </p>
      </div>
    </div>
  );
}

export default Blog5BS;