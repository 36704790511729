import React from 'react';
import './blog.css'; // Import the CSS styling

function Blog6BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Fast Payments, Your Way</h2>
        <p className="ai-precision-text">
        Moccet ensures you're rewarded for your hard work with <span className="highlight">fast payments—within 
        5 days of project approval</span>—wired directly to the account of your choice. 
        This transparent and efficient payment system reflects our commitment 
        to supporting your professional growth and valuing your time and effort.
        </p>
        <p className="ai-precision-text">
        With prompt payments, you can focus on delivering your best work without delays or uncertainty, making every project with Moccet a <span className="highlight">
            seamless and rewarding experience.</span>
        </p>
      </div>
    </div>
  );
}

export default Blog6BS;
