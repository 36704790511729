import React from 'react';
import './AIPrecision.css'; // Import the CSS styling

function Blog6BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Anything You Can Imagine</h2>
        <p className="ai-precision-text">
        From <span className="highlight">pitch decks and brand logos to marketing calendars, 
        copywriting, and website optimization</span>—if you can imagine it, 
        Moccet can make it happen. 
        Our diverse talent pool from <span className="highlight">Harvard, MIT</span>, 
        and other top institutions spans disciplines, 
        allowing you to source expertise across virtually any project type. 
        Moccet’s flexibility means that whatever your business needs, 
        you’ll find qualified professionals ready to take it on.
        </p>
      </div>
    </div>
  );
}

export default Blog6BS;
