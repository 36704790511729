import React from 'react';
import './blog.css'; // Import the CSS styling

function Blog4BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Experience</h2>
        <p className="ai-precision-text">
        With Moccet, your learning extends beyond the classroom. 
        Our platform bridges academia and industry, 
        offering <span className="highlight">real-world projects</span> that allow you to apply your knowledge in practical settings. 
        Each project provides hands-on experience, helping you maximize your time while building skills 
        and a portfolio that <span className="highlight">stand out to future employers.</span>
        </p>
        <p className="ai-precision-text">
        This real-world exposure is invaluable, 
        transforming theoretical knowledge into practice and giving you a head start in <span className="highlight">building a successful career.</span>
        </p>
      </div>
    </div>
  );
}

export default Blog4BS;
