// src/components/SetBudget.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SetBudget.css';
import { auth } from '../../firebaseConfig'; // Reintroduced import for authentication

const SetBudget = () => {
  const [budget, setBudget] = useState(500);
  const location = useLocation();
  const projectData = location.state || {};

  const navigate = useNavigate();

  console.log('Project Data in SetBudget:', projectData);

  const handleSliderChange = (event) => {
    const value = event.target.value;
    setBudget(value);
    const percentage = ((value - 500) / (5000 - 500)) * 100;
    event.target.style.setProperty('--slider-percentage', `${percentage}%`);

    // Update CSS variable on the parent container
    const sliderContainer = event.target.parentElement;
    sliderContainer.style.setProperty('--slider-percentage', `${percentage}%`);
  };

  // Inside handleNext function in SetBudget.js
  const handleNext = () => {
    const targetRoute = auth.currentUser ? '/payment' : '/business-signup';
    console.log("Navigating with state:", { ...projectData, budget });
    navigate(targetRoute, {
      state: {
        ...projectData,
        budget: Number(budget),
      },
    });
  };

  

  return (
    <div className="page-container">
      <div className="set-budget-container">
        <h1 className="title">Customize <span>your</span> budget</h1>
        <p className="text">
        Our AI prices a project of this caliber between <span>$500</span> and <span>$5 000</span>.
        </p>
        <p className="text">
        Expected return is around <span>$10 000</span> a month or <span>$120 000</span> a year. 
        </p>
        <div className="main-content">
          <div className="center-section">
            <div className="slider-container">
              <div className="slider-label left-label">$500</div>
              <div className="slider-track"></div>
              <div className="slider-track-filled"></div>
              <div className="slider-track-thin"></div>
              <input
                type="range"
                min="500"
                max="5000"
                step="50"
                value={budget}
                onChange={handleSliderChange}
                className="budget-slider"
              />
              <div className="slider-label right-label">$5,000</div>
            </div>
            <div className="budget-value">${Number(budget).toLocaleString()}</div>
          </div>
          <div className="upload-button-container">
            <p className="upload-button" onClick={handleNext}>
              Upload
            </p>
          </div>
          <p className="note">
            This payment will be held securely until you are happy that your project has been fulfilled.
            All payments are processed and held securely with Stripe.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SetBudget;
