import React from 'react';
import './Create.css';
import CreateImage from '../../images/create.png';
import { useNavigate } from 'react-router-dom';

const Create = () => {
    const navigate = useNavigate(); // useNavigate hook

      // Function to handle navigation on link clicks
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="create-container">
      <div className="text-section">
        <h1>
        Create projects you love, on your own schedule
        </h1>
        <p>
        Earn on your terms by collaborating on exciting projects. Choose the work that inspires you and get matched with companies looking for top talent.
        </p>
        <p>
        Please note, A Harvard or MIT email address is required to register as talent on Moccet.
        </p>
        <div className="container2">
        <button className="get-started-btn" onClick={() => handleClick('/signup')}>Get started</button>
        <p className="account" onClick={() => handleClick('/signin')}>Already have an account? Sign in</p>
        </div>
      </div>
      <div className="image-section">
        <img src={CreateImage} alt="Create" className="create-image" />
      </div>
    </div>
  );
};

export default Create;