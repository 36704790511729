// src/components/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);

  const placeholders = [
    'Describe your project here...',
    'Need help with marketing?',
    'Looking for a web developer?',
    'Need new logo designs?',
    'Want an MVP for your startup?',
  ];

  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleInputClick = () => {
    // Trigger the page fade animation
    setAnimate(true);
  };

  // Handle navigation after fade animation completes
  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        navigate('/project-optimization');
      }, 300); // Match the CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [animate, navigate]);

  // Placeholder text animation
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);

      setTimeout(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
      }, 400); // Halfway point of the animation

      setTimeout(() => {
        setIsAnimating(false);
      }, 800); // End of the animation

    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`dashboard12 ${animate ? 'open' : ''}`}>
      <header>
        {/* Main Content */}
        <div className="header12">
          <h1 className="logo12">
            Complete any project <br/> with <span>Moccet</span>
          </h1>
        </div>
        <div className="input-fields12">
          <div className="input-container12">
            <input
              className="input-box12"
              type="text"
              readOnly
              onFocus={(e) => e.target.blur()}
              onClick={handleInputClick}
            />
            <div className="placeholder-wrapper">
              <div className={`placeholder-text ${isAnimating ? 'animate' : ''}`}>
                {placeholders[currentPlaceholderIndex]}
              </div>
            </div>
          </div>
          <button className="link-button12">Upload</button>
        </div>
        <p onClick={() => navigate('/signin')} className="footer-text12">
          Log in to see your projects
        </p>
      </header>
    </div>
  );
};

export default Dashboard;
