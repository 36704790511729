import React from 'react';
import './AIPrecision.css'; // Import the CSS styling

function AIPrecision() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">AI-powered precision</h2>
        <p className="ai-precision-text">
          Moccet harnesses <span className="highlight">advanced AI technology</span> to transform your project brief into a
          <span className="highlight"> high-impact, market-aligned proposal</span> before matching you with the ideal candidate from our talent pool.
          By analyzing industry trends, the platform ensures your project brief resonates with the latest market insights,
          <span className="highlight"> enhancing its appeal and potential for success.</span>
        </p>
        <p className="ai-precision-text">
          Our upcoming integration with the <span className="highlight">MANTIS algorithm</span> will amplify this precision, instantly connecting
          your project to the best suited Harvard and MIT talent. This level of custom-fit matching enables companies to confidently
          anticipate high-quality, targeted outcomes for every project.
        </p>
        <p className="ai-precision-text">
          Our AI transcends simple matchmaking—its market-informed refinements boost project completion rates and effectiveness.
          This transformation in project initiation and execution <span className="highlight">redefines how businesses and talent engage</span>,
          making project success not just a goal, but an AI-driven certainty.
        </p>
      </div>
    </div>
  );
}

export default AIPrecision;
