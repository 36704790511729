import React from 'react';
import './blog.css'; // Import the CSS styling

function Blog1T() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Advanced AI Integration</h2>
        <p className="ai-precision-text">
        At Moccet, <span className="highlight">AI empowers you at every step</span>, refining project briefs
         with <span className="highlight">real-world market insights</span> and trends to set you up for success. 
        Our intelligent systems ensure each project is <span className="highlight">well-defined, impactful, and aligned with industry standards</span>, 
        giving you a head start. With upcoming integrations like the <span className="highlight">Mantis algorithm</span>, 
        you'll instantly connect to projects matching your skills and interests—making it easier to focus on what you do best.
        </p>
        <p className="ai-precision-text">
        This advanced <span className="highlight">AI support</span> transforms every project into a high-value opportunity. 
        By handling the groundwork, Moccet's AI allows you to concentrate on applying your expertise and achieving standout results.
        </p>
      </div>
    </div>
  );
}

export default Blog1T;