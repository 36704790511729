import React from 'react';
import './AIPrecision.css'; // Import the CSS styling

function Blog5BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Rapid Results</h2>
        <p className="ai-precision-text">
        Moccet’s streamlined platform ensures projects are completed with 
        remarkable speed—most <span className="highlight">within just 10 days of posting. </span>
        Our AI-driven matching and in-house collaboration tools eliminate common delays, 
        allowing users to dive into projects with <span className="highlight">clarity and focus. </span>
        This rapid turnaround is designed to keep pace with business demands, 
        helping companies achieve outcomes swiftly and without compromising on <span className="highlight">quality.</span>
        </p>
        <p className="ai-precision-text">
        This accelerated workflow is a testament to Moccet’s commitment to 
        <span className="highlight">efficiency in the age of AI.</span> By bridging talent and tasks in record time, 
        we empower businesses to execute their visions faster, 
        giving them a competitive edge in today’s dynamic market.
        </p>
      </div>
    </div>
  );
}

export default Blog5BS;
