import React from 'react';
import './AIPrecision.css'; // Import the CSS styling

function Blog4BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Set Your Value</h2>
        <p className="ai-precision-text">
        With Moccet, businesses have the <span className="highlight">freedom to decide the value of their projects </span>
        by setting flexible funding levels—including cryptocurrency options. 
        This <span className="highlight">innovative funding model puts companies in control</span>, 
        letting them tailor investment to their unique needs and budgets. 
        Once funded, the project enters an open market where the best-qualified 
        talent can select it, creating a <span className="highlight">competitive ecosystem that attracts 
        top talent</span> ready to engage at any scale.
        </p>
        <p className="ai-precision-text">
        The <span className="highlight">"set your value" model</span> makes Moccet an inclusive platform, 
        accessible to businesses of all sizes. 
        By aligning project value directly with talent motivation, 
        this approach brings a new level of <span className="highlight">transparency and empowerment </span>
        to the world of freelance and contract work.
        </p>
      </div>
    </div>
  );
}

export default Blog4BS;
