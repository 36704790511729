import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './StudentDash.css';
import Footer from '../components/Footer';
import rightarrow from '../images/right-arrow.png';
import backgroundVideo from '../videos/moccet.mp4'; // Import the video file

const StudentDash = () => {
  const [loaded, setLoaded] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [firstName, setFirstName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setFirstName(userDoc.data().firstName || '');
        }
      }
    };

    fetchUserName();
  }, []);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <div className={`dashboard-container11 ${loaded ? 'loaded' : ''}`}>
      {/* Background Video */}
      <video autoPlay loop muted playsInline className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className='d123'>
      <div className={`upper-section11`}>
        <div className="welcome-message11">
          <h2>
            Welcome <span>{firstName}</span>
          </h2>
        </div>
      </div>
      <div className="dashboard-links-container11">
        <div className="dashboard-links11">
          <span className="link-item11" onClick={() => navigate('/my-projects')}>
            current projects
          </span>
          <span className="link-item11" onClick={() => navigate('/project-board')}>
            project board
          </span>
          <span className="link-item11" onClick={() => navigate('/student-chats')}>
            collaborate
          </span>
          <span className="link-item11 mobile-only" onClick={() => navigate('/student-profile')}>
            account
          </span>
          <span className="link-item11 mobile-only" onClick={() => navigate('/support')}>
            support
          </span>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      </div>
    </div>
  );
};

export default StudentDash;
