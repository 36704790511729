// src/components/SignUp.js

import React, { useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './MergedSignUp.css';

function SignUp() {
  const [isStudent, setIsStudent] = useState(true); // Toggle between Student and Business
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Common Fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // Student-specific Fields
  const [university, setUniversity] = useState('');
  const [degree, setDegree] = useState('');
  const [year, setYear] = useState('');
  const [skills, setSkills] = useState('');
  const [previousProjects, setPreviousProjects] = useState('');

  // Business-specific Fields
  const [businessName, setBusinessName] = useState('');
  const [intendedProjects, setIntendedProjects] = useState('');

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const allowedDomains = [
    'harvard.edu', 'mit.edu'
  ];

  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleShowTerms = () => {
    setShowTermsPopup(true);
  };

  const handleCloseTerms = () => {
    setShowTermsPopup(false);
  };

  const handleSignUp = async () => {
    setError('');
    setMessage('');
    if (!termsAccepted) {
      setError('You must agree to the Terms & Conditions prior to sign up.');
      return;
    }

    // Normalize email to lowercase for case-insensitive domain check
    const normalizedEmail = email.toLowerCase();

    if (isStudent) {
      const domainAllowed = allowedDomains.some(domain => normalizedEmail.endsWith(domain));
      if (!domainAllowed) {
        setError('Only students from allowed universities can sign up with their university email.');
        return;
      }
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, normalizedEmail, password);
      const user = userCredential.user;

      const userInfo = {
        email: normalizedEmail, // Include the email field here
        role: isStudent ? 'student' : 'business',
        firstName,
        lastName,
        roleAssigned: false,
      };

      if (isStudent) {
        Object.assign(userInfo, {
          university,
          degree,
          year,
          skills,
          previousProjects,
        });
      } else {
        Object.assign(userInfo, { businessName, intendedProjects });
      }

      // Store user info in Firestore
      await setDoc(doc(db, 'users', user.uid), userInfo);

      // Send email verification
      await sendEmailVerification(user);

      // Sign out the user after successful sign-up
      await auth.signOut();

      setMessage('Sign-up successful! Please check your email to verify your account.');
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Error signing up. Please try again.');
    }
  };

  // Handle "Enter" key press to trigger form submission
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignUp();
    }
  };

// Terms and Conditions content for students and business users
const studentTermsAndConditions = (
  <div className="terms-text">
    <h2>Student Terms and Conditions</h2>

    <h3>1. Introduction</h3>
    <p>
      Welcome to Moccet.com. By registering, accessing, and using our platform, you agree to comply with these Terms and Conditions. This agreement governs your relationship with Moccet.com, outlining your responsibilities and rights as a student user. Failure to comply may result in immediate termination of your account.
    </p>

    <h3>2. Eligibility</h3>
    <ul>
      <li>
        <strong>2.1. University Requirement:</strong> Only students currently enrolled at one of the top 25 universities in the world, as defined by Moccet.com, are eligible to register. Valid university credentials must be provided during registration.
      </li>
      <li>
        <strong>2.2. Truthfulness of Information:</strong> If any information provided during registration or use of the platform is found to be false or misleading, Moccet.com reserves the right to terminate your account without notice and withhold any payments.
      </li>
      <li>
        <strong>2.3. Age Requirement:</strong> You must be at least 18 years old to use the platform.
      </li>
    </ul>

    <h3>3. Project Application and Completion</h3>
    <ul>
      <li>
        <strong>3.1. Application:</strong> You may apply for projects listed on the Moccet.com platform. By applying, you commit to completing the project according to the specifications provided by the company.
      </li>
      <li>
        <strong>3.2. Submission:</strong> Upon completing a project, you must submit your work through the Moccet.com platform and mark the project as complete. The company will then review your submission.
      </li>
      <li>
        <strong>3.3. Quality Assurance:</strong> The company will assess the quality of your submission. If the company is not satisfied, both parties must submit a report to Moccet.com. Moccet.com will make the final decision regarding whether the project meets the required standards. It is expected that students will complete projects to the highest standard possible in the quickest time frame possible also.
      </li>
      <li>
        <strong>3.4. Revision Opportunity:</strong> If Moccet.com deems the project incomplete or substandard, you may be allowed one opportunity to revise and resubmit your work within a specified timeframe. Failure to meet the revised standards will result in non-payment and reassignment of the project.
      </li>
    </ul>

    <h3>4. Payment Terms</h3>
    <ul>
      <li>
        <strong>4.1. Payment Release:</strong> Payments will only be released after the company has marked the project as complete and satisfactory. If the company is dissatisfied, Moccet.com may delay or withhold payment until the dispute is resolved.
      </li>
      <li>
        <strong>4.2. Payment Percentage:</strong> You will receive a percentage of the project budget as determined by Moccet.com. This percentage is confidential and not disclosed to you. The remaining balance is retained by Moccet.com as a service fee.
      </li>
      <li>
        <strong>4.3. Responsibility for Payment Details:</strong> You are solely responsible for providing accurate payment details. Moccet.com is not liable for payments made to incorrect accounts due to inaccurate information provided by you.
      </li>
      <li>
        <strong>4.4. Non-Disclosure of Fees:</strong> Moccet.com retains the right to withhold from the student any information regarding the fees deducted from the total budget provided by the company.
      </li>
    </ul>

    <h3>5. Intellectual Property</h3>
    <ul>
      <li>
        <strong>5.1. Ownership Transfer:</strong> All work submitted through the Moccet.com platform becomes the intellectual property of Moccet.com upon submission. This transfer of ownership is automatic and unconditional.
      </li>
      <li>
        <strong>5.2. Assignment of Rights:</strong> Moccet.com retains the exclusive right to transfer or assign the intellectual property rights of the submitted work to the company that commissioned the project.
      </li>
    </ul>

    <h3>6. Restrictions</h3>
    <ul>
      <li>
        <strong>6.1. Platform Exclusivity:</strong> All communications, negotiations, and project work must remain within the Moccet.com platform. Engaging in any off-platform discussions or agreements related to the project without Moccet.com’s written consent is strictly prohibited.
      </li>
      <li>
        <strong>6.2. Prohibition on Side Projects:</strong> You are prohibited from accepting or performing any side projects for the same company outside the Moccet.com platform unless explicitly authorized by Moccet.com.
      </li>
      <li>
        <strong>6.3. Employment Offers:</strong> If a company offers you a full-time position, this must be reported to Moccet.com. Employment offers may require a recruitment fee payable to Moccet.com, and failure to comply will result in legal action.
      </li>
    </ul>

    <h3>7. Liability and Indemnity</h3>
    <ul>
      <li>
        <strong>7.1. Limitation of Liability:</strong> Moccet.com is not responsible for any indirect, incidental, or consequential damages arising from your use of the platform. This includes, but is not limited to, lost opportunities, lost wages, or any reputational damage.
      </li>
      <li>
        <strong>7.2. Indemnity:</strong> You agree to indemnify, defend, and hold harmless Moccet.com from any claims, damages, losses, liabilities, and expenses arising from your breach of these Terms and Conditions or your use of the platform.
      </li>
    </ul>

    <h3>8. Dispute Resolution</h3>
    <ul>
      <li>
        <strong>8.1. Binding Arbitration:</strong> Any disputes arising under these Terms and Conditions shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration decision will be final and binding on all parties.
      </li>
      <li>
        <strong>8.2. Jurisdiction:</strong> The arbitration shall take place in Massachusetts, and any legal issues arising from this agreement shall be governed by the laws of the United States and the State of Massachusetts.
      </li>
    </ul>

    <h3>9. Modifications and Amendments</h3>
    <ul>
      <li>
        <strong>9.1. Right to Amend:</strong> Moccet.com reserves the right to modify these Terms and Conditions at any time without prior notice. Any changes will be posted on the Moccet.com platform, and continued use of the platform constitutes acceptance of these changes.
      </li>
    </ul>

    <h3>10. Governing Law</h3>
    <ul>
      <li>
        <strong>10.1. Legal Compliance:</strong> These Terms and Conditions shall be governed by and construed in accordance with the laws of the United States, with jurisdiction in the courts of Massachusetts.
      </li>
    </ul>

    <h3>11. Confidentiality and Non-Disclosure</h3>
    <ul>
      <li>
        <strong>11.1. Confidential Information:</strong> You acknowledge that during your use of the Moccet.com platform, you may have access to confidential and proprietary information, including but not limited to project details, company information, business strategies, technical data, client lists, and other sensitive information ("Confidential Information").
      </li>
      <li>
        <strong>11.2. Non-Disclosure Obligation:</strong> You agree not to disclose, publish, reproduce, or disseminate any Confidential Information to any third party without the prior written consent of Moccet.com or the company that provided the information.
      </li>
      <li>
        <strong>11.3. Use of Confidential Information:</strong> You agree to use the Confidential Information solely for the purpose of completing projects assigned through the Moccet.com platform and not for any other purpose.
      </li>
      <li>
        <strong>11.4. Protection of Confidential Information:</strong> You agree to take all reasonable measures to protect the confidentiality of the Confidential Information and to prevent any unauthorized use or disclosure.
      </li>
      <li>
        <strong>11.5. Exceptions:</strong> The obligations under this section do not apply to information that:
        <ul>
          <li>
            <strong>a.</strong> Is or becomes publicly available without breach of these Terms and Conditions.
          </li>
          <li>
            <strong>b.</strong> Was in your possession before receipt from Moccet.com.
          </li>
          <li>
            <strong>c.</strong> Is obtained by you from a third party without obligation of confidentiality.
          </li>
          <li>
            <strong>d.</strong> Is required to be disclosed by law or governmental order, provided you give prompt notice to Moccet.com to allow it to seek a protective order or other appropriate remedy.
          </li>
        </ul>
      </li>
      <li>
        <strong>11.6. Return or Destruction of Information:</strong> Upon completion of a project or termination of your account, you agree to return or destroy all Confidential Information as directed by Moccet.com, including all copies, notes, or other materials containing or derived from Confidential Information.
      </li>
      <li>
        <strong>11.7. Injunctive Relief:</strong> You acknowledge that any breach of this confidentiality obligation may cause irreparable harm to Moccet.com or the company, and that monetary damages may not be a sufficient remedy. Therefore, Moccet.com or the company may seek injunctive relief and any other available legal remedies in the event of a breach.
      </li>
    </ul>
  </div>
);

const businessTermsAndConditions = (
  <div className="terms-text">
    <h2>Business Terms and Conditions</h2>

    <h3>1. Introduction</h3>
    <p>
      Welcome to Moccet.com. By accessing, registering, and uploading projects on our platform, you agree to these Terms and Conditions. This agreement governs your relationship with Moccet.com and outlines your responsibilities as a company user. Failure to adhere to these terms may result in termination of your account and forfeiture of any fees paid.
    </p>

    <h3>2. Project Posting and Management</h3>
    <ul>
      <li>
        <strong>2.1. Posting:</strong> As a company, you may post projects on Moccet.com. You are required to provide detailed and accurate project specifications, including budget, timelines, and required skills. Moccet.com is not liable for any project discrepancies resulting from inaccurate information provided by you.
      </li>
      <li>
        <strong>2.2. Student Selection:</strong> You may select one or more students to complete your project based on their applications. Students are allowed to collaborate with other students if they deem it necessary for the successful completion of the project. Such collaboration may involve profit-sharing among students, which Moccet.com is not responsible for overseeing.
      </li>
    </ul>

    <h3>3. Payment and Fees</h3>
    <ul>
      <li>
        <strong>3.1. Payment Terms:</strong> Upon posting a project, you agree to deposit the full project budget into an escrow account managed by Moccet.com. These funds will be held until the project is completed to your satisfaction or as otherwise determined by Moccet.com.
      </li>
      <li>
        <strong>3.2. Fee Structure:</strong> Moccet.com retains a percentage of the project budget as a service fee. This fee is non-refundable, non-negotiable, and not disclosed to either students or companies. Moccet.com reserves the right to modify this fee at any time, including increasing it to the maximum allowable limit or decreasing it at its sole discretion.
      </li>
      <li>
        <strong>3.3. Use of Funds:</strong> Moccet.com reserves the right to use escrowed funds at its discretion while the project is in progress, including but not limited to investment purposes. However, these funds will be available for release according to the terms outlined in this agreement.
      </li>
    </ul>

    <h3>4. Project Review and Completion</h3>
    <ul>
      <li>
        <strong>4.1. Review Process:</strong> Upon receiving the completed project, you are required to review the work and either approve or reject it based on whether it meets the agreed-upon criteria. Although there is no set timeline for the completion of projects, students are expected to deliver high-quality work in the shortest time possible.
      </li>
      <li>
        <strong>4.2. Dispute Resolution:</strong> If you are dissatisfied with the completed work, Moccet.com will conduct a review to determine if the project meets the required standards. Moccet.com’s decision is final. If the work is deemed satisfactory, no refund will be provided. If unsatisfactory, Moccet.com may allow the student to revise the work or reassign the project to another student.
      </li>
      <li>
        <strong>4.3. Revisions:</strong> Should the project be deemed unsatisfactory, the student may be granted one opportunity to revise the work within a 7-day period. If the revision does not meet your expectations, Moccet.com reserves the right to reassign the project to another student or issue a partial refund at its discretion.
      </li>
    </ul>

    <h3>5. Restrictions</h3>
    <ul>
      <li>
        <strong>5.1. Platform Exclusivity:</strong> You are prohibited from engaging students outside of the Moccet.com platform for the same or similar projects without the written consent of Moccet.com. Breaching this exclusivity clause may result in legal action and termination of your account.
      </li>
      <li>
        <strong>5.2. Full-Time Employment Offers:</strong> Should you wish to offer a student full-time employment, you must notify Moccet.com and obtain written approval. A recruitment fee may apply. Failure to comply with this requirement may result in legal consequences and additional fees.
      </li>
    </ul>

    <h3>6. Intellectual Property</h3>
    <ul>
      <li>
        <strong>6.1. Transfer of Rights:</strong> Upon the completion of the project and full payment, Moccet.com will transfer the intellectual property rights of the completed work to your company. This transfer is contingent upon adherence to all terms and conditions, and Moccet.com retains the right to withhold or delay this transfer if any terms are breached.
      </li>
    </ul>

    <h3>7. Liability and Indemnity</h3>
    <ul>
      <li>
        <strong>7.1. Limitation of Liability:</strong> Moccet.com is not liable for any indirect, incidental, or consequential damages resulting from your use of the platform, including but not limited to lost profits, delays, or business interruptions.
      </li>
      <li>
        <strong>7.2. Indemnity:</strong> You agree to indemnify, defend, and hold Moccet.com harmless from any claims, damages, losses, liabilities, and expenses (including legal fees) arising out of your breach of these Terms and Conditions or your use of the platform.
      </li>
    </ul>

    <h3>8. Dispute Resolution</h3>
    <ul>
      <li>
        <strong>8.1. Binding Arbitration:</strong> Any disputes arising under these Terms and Conditions shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The decision rendered by the arbitration process is final and binding on all parties.
      </li>
      <li>
        <strong>8.2. Jurisdiction:</strong> Arbitration shall take place in Massachusetts, and all legal matters shall be governed by the laws of the United States.
      </li>
    </ul>

    <h3>9. Modifications and Amendments</h3>
    <ul>
      <li>
        <strong>9.1. Right to Amend:</strong> Moccet.com reserves the right to modify these Terms and Conditions at any time without prior notice. Any changes will be posted on the Moccet.com platform, and continued use of the platform constitutes acceptance of these changes.
      </li>
    </ul>

    <h3>10. Governing Law</h3>
    <ul>
      <li>
        <strong>10.1. Legal Compliance:</strong> These Terms and Conditions are governed by and construed in accordance with the laws of the United States.
      </li>
    </ul>

    <h3>11. Confidentiality and Non-Disclosure</h3>
    <ul>
      <li>
        <strong>11.1. Confidential Information:</strong> You acknowledge that during your use of the Moccet.com platform, you may receive or have access to confidential and proprietary information from students or Moccet.com, including but not limited to personal information, project proposals, technical data, business strategies, and other sensitive information ("Confidential Information").
      </li>
      <li>
        <strong>11.2. Non-Disclosure Obligation:</strong> You agree not to disclose, publish, reproduce, or disseminate any Confidential Information obtained through the platform to any third party without the prior written consent of Moccet.com or the party that provided the information.
      </li>
      <li>
        <strong>11.3. Use of Confidential Information:</strong> You agree to use the Confidential Information solely for the purpose of engaging with students through the Moccet.com platform and not for any other purpose.
      </li>
      <li>
        <strong>11.4. Protection of Confidential Information:</strong> You agree to take all reasonable measures to protect the confidentiality of the Confidential Information and to prevent any unauthorized use or disclosure.
      </li>
      <li>
        <strong>11.5. Exceptions:</strong> The obligations under this section do not apply to information that:
        <ul>
          <li>
            <strong>a.</strong> Is or becomes publicly available without breach of these Terms and Conditions.
          </li>
          <li>
            <strong>b.</strong> Was in your possession before receipt from Moccet.com or the student.
          </li>
          <li>
            <strong>c.</strong> Is obtained by you from a third party without obligation of confidentiality.
          </li>
          <li>
            <strong>d.</strong> Is required to be disclosed by law or governmental order, provided you give prompt notice to Moccet.com to allow it to seek a protective order or other appropriate remedy.
          </li>
        </ul>
      </li>
      <li>
        <strong>11.6. Return or Destruction of Information:</strong> Upon completion of a project or termination of your account, you agree to return or destroy all Confidential Information as directed by Moccet.com, including all copies, notes, or other materials containing or derived from Confidential Information.
      </li>
      <li>
        <strong>11.7. Injunctive Relief:</strong> You acknowledge that any breach of this confidentiality obligation may cause irreparable harm to Moccet.com or the student, and that monetary damages may not be a sufficient remedy. Therefore, Moccet.com or the affected party may seek injunctive relief and any other available legal remedies in the event of a breach.
      </li>
    </ul>
  </div>
);

  // Determine which Terms and Conditions to display based on user role
  const termsAndConditions = isStudent ? studentTermsAndConditions : businessTermsAndConditions;

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      <div className="role-selection">
        <span
          className={isStudent ? 'active' : ''}
          onClick={() => setIsStudent(true)}
        >
          Harvard/MIT
        </span>
        <span
          className={!isStudent ? 'active' : ''}
          onClick={() => setIsStudent(false)}
        >
          Business
        </span>
      </div>
      <div className="form-container123">
        {isStudent ? (
          <>
            <div className="form-labels123">
              <p>First Name</p>
              <p>Last Name</p>
              <p>University Email</p>
              <p>University</p>
              <p>Degree</p>
              <p>Graduation Year</p>
              <p>Skills</p>
              <p>Previous Projects</p>
              <p>Password</p>
            </div>
            <div className="form-inputs123">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                onKeyDown={handleKeyDown}
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="University Email"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={university}
                onChange={(e) => setUniversity(e.target.value)}
                placeholder="University"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
                placeholder="Degree"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                placeholder="Graduation Year"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
                placeholder="Skills"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={previousProjects}
                onChange={(e) => setPreviousProjects(e.target.value)}
                placeholder="Previous Projects"
                onKeyDown={handleKeyDown}
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                onKeyDown={handleKeyDown}
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-labels123">
              <p>First Name</p>
              <p>Last Name</p>
              <p>Email Address</p>
              <p>Business Name</p>
              <p>Intended Projects</p>
              <p>Password</p>
            </div>
            <div className="form-inputs123">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                onKeyDown={handleKeyDown}
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                placeholder="Business Name"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={intendedProjects}
                onChange={(e) => setIntendedProjects(e.target.value)}
                placeholder="Intended Projects"
                onKeyDown={handleKeyDown}
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                onKeyDown={handleKeyDown}
              />
            </div>
          </>
        )}
      </div>
      <div className="terms-checkbox">
  <label>
    <input
      type="checkbox"
      checked={termsAccepted}
      onChange={(e) => setTermsAccepted(e.target.checked)}
    />
    I accept the&nbsp;
    <span className="terms-link" onClick={handleShowTerms}>
      Terms and Conditions
    </span>
  </label>
</div>
{error && <p className="error-text">{error}</p>}
<p className="send-verification" onClick={handleSignUp}>
  Send Verification
</p>
<p className="login-text">
  Already a member?{' '}
  <span className="login" onClick={() => navigate('/signin')}>
    Log In
  </span>
</p>
{message && <p className="success-text">{message}</p>}


      {/* Terms and Conditions Popup */}
      {showTermsPopup && (
        <div className="terms-popup">
          <div className="terms-popup-content">
            <div className="terms-header">
              <h2>{isStudent ? 'Student' : 'Business'} Terms and Conditions</h2>
              <span className="close-popup" onClick={handleCloseTerms}>
                &times;
              </span>
            </div>
            <div className="terms-content">
              {termsAndConditions}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUp;



  