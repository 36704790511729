import React from 'react';
import './AIPrecision.css'; // Import the CSS styling

function Blog2BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Exclusively the Best</h2>
        <p className="ai-precision-text">
        Moccet offers <span className="highlight">unparalleled access to a curated network of talent</span> from the world's top institutions. 
        Each project uploaded to Moccet is available only to those who have met the <span className="highlight">rigorous standards</span>  of most exclusive institutions, 
        ensuring you're tapping into the <span className="highlight">brightest minds.</span> 
        We're committed to raising the bar on short-term project success by providing access to high-performing, 
        motivated individuals who bring cutting-edge knowledge and innovative thinking to the table.
        </p>
        <p className="ai-precision-text">
        By focusing on talent from elite institutions, 
        Moccet sets a <span className="highlight">new benchmark in project quality.</span> 
        Each partnership benefits from trusted commitment to excellence and 
        keen understanding of industry trends. 
        With Moccet, you're not just hiring talent—you're gaining <span className="highlight">VIP access </span>
        to a powerhouse of academic and practical expertise.
        </p>
      </div>
    </div>
  );
}

export default Blog2BS;
