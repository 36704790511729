// src/components/chat/BusinessChatPage.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  setDoc,
  onSnapshot,
  query,
  where,
  increment,
} from 'firebase/firestore';
import './BusinessChatPage.css';

function BusinessChatPage() {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [currentUserFirstName, setCurrentUserFirstName] = useState('');
  const [otherUserFirstName, setOtherUserFirstName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [studentName, setStudentName] = useState('');
  const messagesEndRef = useRef(null);

  const formatTimeSent = (timestamp) => {
    const now = new Date();
    const timeDiff = now - timestamp;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (days >= 2) return `${days} days ago`;
    if (days === 1) return 'Yesterday';
    if (hours >= 1) return `${hours} hours ago`;
    if (minutes >= 1) return `${minutes} minutes ago`;
    return 'Just now';
  };

  const fetchChatDetails = async (chatData = {}) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setCurrentUserFirstName(userDoc.data().firstName);
      }

      const messagesArray = chatData.messages || [];
      let otherUserId = null;
      if (messagesArray.length > 0) {
        otherUserId =
          messagesArray[0].senderId !== user.uid
            ? messagesArray[0].senderId
            : null;
      }

      if (!otherUserId) {
        const chatIdParts = chatId.split('_');
        otherUserId = chatIdParts.find((id) => id !== user.uid);
      }

      if (otherUserId) {
        const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
        if (otherUserDoc.exists()) {
          setOtherUserFirstName(otherUserDoc.data().firstName);
          setStudentName(otherUserDoc.data().firstName);
        }

        const applicationsRef = collection(db, 'applications');
        const q = query(
          applicationsRef,
          where('businessId', '==', user.uid),
          where('studentId', '==', otherUserId),
          where('isCollaborating', '==', true)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const applicationData = querySnapshot.docs[0].data();
          setProjectName(applicationData.projectName);
          setStudentName(applicationData.studentName);

          const chatRef = doc(db, 'chatRooms', chatId);
          await updateDoc(chatRef, {
            projectName: applicationData.projectName,
            studentName: applicationData.studentName,
          });
        } else {
          setProjectName('Unnamed Project');
          setStudentName('Unnamed Student');
        }
      }
    } catch (error) {
      console.error('Error fetching chat details:', error);
    }
  };

  const getOtherUserId = () => {
    const user = auth.currentUser;
    if (!user) return null;

    const chatIdParts = chatId.split('_');
    return chatIdParts.find((id) => id !== user.uid);
  };

  useEffect(() => {
    const chatRef = doc(db, 'chatRooms', chatId);

    const unsubscribe = onSnapshot(chatRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const chatData = docSnapshot.data();
        setMessages(chatData.messages || []);

        const user = auth.currentUser;
        if (user) {
          await updateDoc(chatRef, {
            [`unreadCounts.${user.uid}`]: 0,
          });
        }

        if (chatData.projectName && chatData.studentName) {
          setProjectName(chatData.projectName);
          setStudentName(chatData.studentName);
        } else {
          fetchChatDetails(chatData);
        }
      } else {
        await setDoc(chatRef, { messages: [], unreadCounts: {} });
        fetchChatDetails();
      }
    });

    return () => unsubscribe();
  }, [chatId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const chatRef = doc(db, 'chatRooms', chatId);

    const message = {
      senderId: auth.currentUser.uid,
      message: newMessage,
      timestamp: new Date(),
    };

    try {
      const otherUserId = getOtherUserId();

      await updateDoc(chatRef, {
        messages: arrayUnion(message),
      });

      await updateDoc(chatRef, {
        [`unreadCounts.${otherUserId}`]: increment(1),
      });

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-container">
      {/* Display projectName X studentName at the top */}
      <div className="chat-header1">
        {`${projectName} `}
        <span className="chat-header-x">X</span>
        {` ${studentName}`}
      </div>

      <div className="messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${
              msg.senderId === auth.currentUser.uid ? 'sender' : 'recipient'
            }`}
          >
            {msg.senderId !== auth.currentUser.uid && (
              <span className="avatar">{otherUserFirstName.charAt(0)}</span>
            )}
            <span className="message-content1">{msg.message}</span>
            <span className="user-name">
              {msg.senderId === auth.currentUser.uid
                ? currentUserFirstName
                : otherUserFirstName}
            </span>
            {msg.senderId !== auth.currentUser.uid && index === messages.length - 1 && (
              <div className="time-sent">
                {formatTimeSent(msg.timestamp.toDate())}
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Ref to scroll to */}
      </div>
      <div className="input-container2">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="type here..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSendMessage();
          }}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
}

export default BusinessChatPage;
