import React from 'react';
import './blog.css'; // Import the CSS styling

function Blog2BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Lead Your Passion</h2>
        <p className="ai-precision-text">
        With Moccet, you're in the driver's seat—select projects that align with your passion, 
        take ownership, and <span className="highlight">watch your work make an impact. </span>
        You'll have the autonomy to work on what truly excites you, 
        with the added assurance of timely, secure payment via wire transfer upon project completion. 
        It's the freedom to <span className="highlight">build your career on your terms</span>, gaining practical experience while doing work that matters.
        </p>
        <p className="ai-precision-text">
        This opportunity isn't just about earning—it's about <span className="highlight">shaping your journey meaningfully. </span>
        With each project, you're building a portfolio that showcases your  <span className="highlight">skills, interests, and dedication to impactful work.</span>
        </p>
      </div>
    </div>
  );
}

export default Blog2BS;