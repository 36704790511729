import React from 'react';
import './blog.css'; // Import the CSS styling

function Blog3BS() {
  return (
    <div className="ai-precision-page">
      <div className="ai-precision-container">
        <h2 className="ai-precision-title">Collaborate + (Collaborate Plus)</h2>
        <p className="ai-precision-text">
        By joining Moccet, you're not just accessing projects; 
        you're entering a <span className="highlight">global knowledge hub</span> connecting you with talent across <span className="highlight">Harvard and MIT </span>in one space. 
        Moccet's free collaboration platform brings together a <span className="highlight">diverse network</span>, 
        enabling you to share ideas, insights, and strategies with equally driven and talented peers.
        </p>
        <p className="ai-precision-text">
        This collaborative environment offers a unique space to learn, 
        innovate, and grow beyond the classroom. 
        Moccet fosters a community where you can <span className="highlight">connect</span> with like-minded individuals, 
        helping you broaden your perspectives and  <span className="highlight">elevate your work.</span>
        </p>
      </div>
    </div>
  );
}

export default Blog3BS;
