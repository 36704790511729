import React, { useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import './BusinessSignUp.css';
import appleLogo from '../../images/apple.png';
import googleLogo from '../../images/google.png';

function BusinessSignUp() {
const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const projectData = location.state || {};

     // Log the state received when BusinessSignUp loads
    console.log('State received in BusinessSignUp:', location.state);
  const handleShowTerms = () => {
    setShowTermsPopup(true);
  };

  const handleCloseTerms = () => {
    setShowTermsPopup(false);
  };

  const handleSignUp = async () => {
    setError('');
    setMessage('');
    if (!termsAccepted) {
      setError('You must agree to the Terms & Conditions prior to sign up.');
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      const userInfo = {
        email,
        role: 'business',
        roleAssigned: false,
      };
  
      await setDoc(doc(db, 'users', user.uid), userInfo);
  
      // Immediately log in the user
      await signInWithEmailAndPassword(auth, email, password);
      
      // Navigate to the Stripe payment page
      navigate('/payment', {
        state: {
          ...projectData,
        },
      });
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Error signing up. Please try again.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Reference to the user document in Firestore
      const userDocRef = doc(db, 'users', user.uid);
  
      // Check if user document already exists
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        const userInfo = {
          email: user.email,
          role: 'business', // Adjust as needed
          roleAssigned: false,
        };
        // Add user to Firestore and wait for completion
        await setDoc(userDocRef, userInfo);
      }
  
      // Pass projectData when navigating
      navigate('/payment', {
        state: {
          ...projectData,
        },
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Error signing in with Google. Please try again.');
    }
  };
  
  
  const handleAppleSignIn = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
      await signInWithPopup(auth, provider);
      navigate('/payment');
    } catch (error) {
      console.error('Error signing in with Apple:', error);
      setError('Error signing in with Apple. Please try again.');
    }
  };

  

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="terms-checkbox">
        <label>
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          I agree to Moccet’s&nbsp;<span className="terms-link" onClick={handleShowTerms}>Terms of Service and Privacy Policy</span>.
        </label>
      </div>
      <button className="continue-button" onClick={handleSignUp}>Continue</button>
      {error && <p className="error-text">{error}</p>}
      {message && <p className="success-text">{message}</p>}

      <hr className="divider" />

      {/* <button className="social-button apple-button" onClick={handleAppleSignIn}>
  <img src={appleLogo} alt="Apple Logo" className="social-logo-apple" />
  Continue with Apple
</button> */}
<button className="social-button google-button" onClick={handleGoogleSignIn}>
  <img src={googleLogo} alt="Google Logo" className="social-logo" />
  Continue with Google
</button>

      {showTermsPopup && (
        <div className="terms-popup">
          <div className="terms-popup-content">
            <div className="terms-header">
              <h2>Terms and Conditions</h2>
              <span className="close-popup" onClick={handleCloseTerms}>&times;</span>
            </div>
            <div className="terms-content">
    <h2>Business Terms and Conditions</h2>

    <h3>1. Introduction</h3>
    <p>
      Welcome to Moccet.com. By accessing, registering, and uploading projects on our platform, you agree to these Terms and Conditions. This agreement governs your relationship with Moccet.com and outlines your responsibilities as a company user. Failure to adhere to these terms may result in termination of your account and forfeiture of any fees paid.
    </p>

    <h3>2. Project Posting and Management</h3>
    <ul>
      <li>
        <strong>2.1. Posting:</strong> As a company, you may post projects on Moccet.com. You are required to provide detailed and accurate project specifications, including budget, timelines, and required skills. Moccet.com is not liable for any project discrepancies resulting from inaccurate information provided by you.
      </li>
      <li>
        <strong>2.2. Student Selection:</strong> You may select one or more students to complete your project based on their applications. Students are allowed to collaborate with other students if they deem it necessary for the successful completion of the project. Such collaboration may involve profit-sharing among students, which Moccet.com is not responsible for overseeing.
      </li>
    </ul>

    <h3>3. Payment and Fees</h3>
    <ul>
      <li>
        <strong>3.1. Payment Terms:</strong> Upon posting a project, you agree to deposit the full project budget into an escrow account managed by Moccet.com. These funds will be held until the project is completed to your satisfaction or as otherwise determined by Moccet.com.
      </li>
      <li>
        <strong>3.2. Fee Structure:</strong> Moccet.com retains a percentage of the project budget as a service fee. This fee is non-refundable, non-negotiable, and not disclosed to either students or companies. Moccet.com reserves the right to modify this fee at any time, including increasing it to the maximum allowable limit or decreasing it at its sole discretion.
      </li>
      <li>
        <strong>3.3. Use of Funds:</strong> Moccet.com reserves the right to use escrowed funds at its discretion while the project is in progress, including but not limited to investment purposes. However, these funds will be available for release according to the terms outlined in this agreement.
      </li>
    </ul>

    <h3>4. Project Review and Completion</h3>
    <ul>
      <li>
        <strong>4.1. Review Process:</strong> Upon receiving the completed project, you are required to review the work and either approve or reject it based on whether it meets the agreed-upon criteria. Although there is no set timeline for the completion of projects, students are expected to deliver high-quality work in the shortest time possible.
      </li>
      <li>
        <strong>4.2. Dispute Resolution:</strong> If you are dissatisfied with the completed work, Moccet.com will conduct a review to determine if the project meets the required standards. Moccet.com’s decision is final. If the work is deemed satisfactory, no refund will be provided. If unsatisfactory, Moccet.com may allow the student to revise the work or reassign the project to another student.
      </li>
      <li>
        <strong>4.3. Revisions:</strong> Should the project be deemed unsatisfactory, the student may be granted one opportunity to revise the work within a 7-day period. If the revision does not meet your expectations, Moccet.com reserves the right to reassign the project to another student or issue a partial refund at its discretion.
      </li>
    </ul>

    <h3>5. Restrictions</h3>
    <ul>
      <li>
        <strong>5.1. Platform Exclusivity:</strong> You are prohibited from engaging students outside of the Moccet.com platform for the same or similar projects without the written consent of Moccet.com. Breaching this exclusivity clause may result in legal action and termination of your account.
      </li>
      <li>
        <strong>5.2. Full-Time Employment Offers:</strong> Should you wish to offer a student full-time employment, you must notify Moccet.com and obtain written approval. A recruitment fee may apply. Failure to comply with this requirement may result in legal consequences and additional fees.
      </li>
    </ul>

    <h3>6. Intellectual Property</h3>
    <ul>
      <li>
        <strong>6.1. Transfer of Rights:</strong> Upon the completion of the project and full payment, Moccet.com will transfer the intellectual property rights of the completed work to your company. This transfer is contingent upon adherence to all terms and conditions, and Moccet.com retains the right to withhold or delay this transfer if any terms are breached.
      </li>
    </ul>

    <h3>7. Liability and Indemnity</h3>
    <ul>
      <li>
        <strong>7.1. Limitation of Liability:</strong> Moccet.com is not liable for any indirect, incidental, or consequential damages resulting from your use of the platform, including but not limited to lost profits, delays, or business interruptions.
      </li>
      <li>
        <strong>7.2. Indemnity:</strong> You agree to indemnify, defend, and hold Moccet.com harmless from any claims, damages, losses, liabilities, and expenses (including legal fees) arising out of your breach of these Terms and Conditions or your use of the platform.
      </li>
    </ul>

    <h3>8. Dispute Resolution</h3>
    <ul>
      <li>
        <strong>8.1. Binding Arbitration:</strong> Any disputes arising under these Terms and Conditions shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The decision rendered by the arbitration process is final and binding on all parties.
      </li>
      <li>
        <strong>8.2. Jurisdiction:</strong> Arbitration shall take place in Massachusetts, and all legal matters shall be governed by the laws of the United States.
      </li>
    </ul>

    <h3>9. Modifications and Amendments</h3>
    <ul>
      <li>
        <strong>9.1. Right to Amend:</strong> Moccet.com reserves the right to modify these Terms and Conditions at any time without prior notice. Any changes will be posted on the Moccet.com platform, and continued use of the platform constitutes acceptance of these changes.
      </li>
    </ul>

    <h3>10. Governing Law</h3>
    <ul>
      <li>
        <strong>10.1. Legal Compliance:</strong> These Terms and Conditions are governed by and construed in accordance with the laws of the United States.
      </li>
    </ul>

    <h3>11. Confidentiality and Non-Disclosure</h3>
    <ul>
      <li>
        <strong>11.1. Confidential Information:</strong> You acknowledge that during your use of the Moccet.com platform, you may receive or have access to confidential and proprietary information from students or Moccet.com, including but not limited to personal information, project proposals, technical data, business strategies, and other sensitive information ("Confidential Information").
      </li>
      <li>
        <strong>11.2. Non-Disclosure Obligation:</strong> You agree not to disclose, publish, reproduce, or disseminate any Confidential Information obtained through the platform to any third party without the prior written consent of Moccet.com or the party that provided the information.
      </li>
      <li>
        <strong>11.3. Use of Confidential Information:</strong> You agree to use the Confidential Information solely for the purpose of engaging with students through the Moccet.com platform and not for any other purpose.
      </li>
      <li>
        <strong>11.4. Protection of Confidential Information:</strong> You agree to take all reasonable measures to protect the confidentiality of the Confidential Information and to prevent any unauthorized use or disclosure.
      </li>
      <li>
        <strong>11.5. Exceptions:</strong> The obligations under this section do not apply to information that:
        <ul>
          <li>
            <strong>a.</strong> Is or becomes publicly available without breach of these Terms and Conditions.
          </li>
          <li>
            <strong>b.</strong> Was in your possession before receipt from Moccet.com or the student.
          </li>
          <li>
            <strong>c.</strong> Is obtained by you from a third party without obligation of confidentiality.
          </li>
          <li>
            <strong>d.</strong> Is required to be disclosed by law or governmental order, provided you give prompt notice to Moccet.com to allow it to seek a protective order or other appropriate remedy.
          </li>
        </ul>
      </li>
      <li>
        <strong>11.6. Return or Destruction of Information:</strong> Upon completion of a project or termination of your account, you agree to return or destroy all Confidential Information as directed by Moccet.com, including all copies, notes, or other materials containing or derived from Confidential Information.
      </li>
      <li>
        <strong>11.7. Injunctive Relief:</strong> You acknowledge that any breach of this confidentiality obligation may cause irreparable harm to Moccet.com or the student, and that monetary damages may not be a sufficient remedy. Therefore, Moccet.com or the affected party may seek injunctive relief and any other available legal remedies in the event of a breach.
      </li>
    </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessSignUp;